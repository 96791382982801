const dev = {
	// API_ENDPOINT_URL: 'https://8aea-181-39-250-74.ngrok-free.app/api/v1/laas',
	API_ENDPOINT_URL: 'https://api.lexis.com.ec/api/v1/laas',
	API_PDF_URL: 'https://api.lexis.com.ec/api/v1/pdf'
};

const prod = {
	API_ENDPOINT_URL: 'https://api.lexis.com.ec/api/v1/laas',
	API_PDF_URL: 'https://api.lexis.com.ec/api/v1/pdf'
};

const test = {
	API_ENDPOINT_URL: 'https://api.test.com',
	API_PDF_URL: 'https://servicespdf.lexis.com.ec'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()


